@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100vh;
}

body {
  min-height: 100%;
  display: flex;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* ---------------------------------------------------------------------------------------- */
/* ---------------------------------------- LOADER ---------------------------------------- */
/* ---------------------------------------------------------------------------------------- */

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  display: block;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #818CF8;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.loader:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #4F46E5;
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}

.loader:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #3730A3;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* ---------------------------------------------------------------------------------------- */
/* -------------------------------------- INPUT FILE -------------------------------------- */
/* ---------------------------------------------------------------------------------------- */

.div-input-file {
  display: flex;
  align-items: center;
}

  .label-input-file {
      padding: 10px 15px;
      border-radius: 3px;
      border: 1px solid lightgray;
      cursor: pointer;
      margin-bottom: 0px;
  }

  .label-input-file:hover {
      border-color: #1976d2;
      color: #1976d2;
  }

/* ---------------------------------------------------------------------------------------- */
/* ------------------------------------- INPUT SEARCH ------------------------------------- */
/* ---------------------------------------------------------------------------------------- */

.input-search {
  width: 200px;
}

/* ---------------------------------------------------------------------------------------- */
/* ------------------------------------- INPUT FILTER ------------------------------------- */
/* ---------------------------------------------------------------------------------------- */

.input-filter {
  height: 38px;
}

/* ---------------------------------------------------------------------------------------- */
/* ----------------------------------- DATE PICKER SEARCH --------------------------------- */
/* ---------------------------------------------------------------------------------------- */

.date-picker-search {
  width: 140px;
}


/* ---------------------------------------------------------------------------------------- */
/* ------------------------------------- PHOTO SELECTOR ----------------------------------- */
/* ---------------------------------------------------------------------------------------- */

.photo-selector {
  width: 160px;
  height: 160px;
  border-radius: 6px;
  border-style: solid;
  border-width: 2px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

  .photo-selector i {
    color: lightgray;
  }

  .photo-selector div {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .photo-selector:hover i {
    color: white;
  }

/* ---------------------------------------------------------------------------------------- */
/* ------------------------------------ PHOTO DISPLAYER ----------------------------------- */
/* ---------------------------------------------------------------------------------------- */

.photo-displayer {
  width: 160px;
  height: 160px;
  border-radius: 6px;
  border-style: solid;
  border-width: 2px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
}

  .photo-displayer div {
    display: none;
    align-items: center;
    justify-content: center;
    height: 100%;
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    font-size: 30px;
  }

  .photo-displayer:hover div {
    display: flex;
  }

/* ---------------------------------------------------------------------------------------- */
/* --------------------------------------- ITEM CARD -------------------------------------- */
/* ---------------------------------------------------------------------------------------- */

.item-card-container {
  height: 160px;
  border-radius: 6px;
  border-width: 2px;
  border-style: solid;
  display: flex;
  flex-direction: column;
}

.item-card-container-hover {
  cursor: pointer;
}

  .item-card-container-hover:hover .item-card-title {
    background-color: rgba(99, 102, 241);
    color: white;
  }

.item-card-image {
  flex: 1;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.item-card-title {
  padding: 6px;
  border-top-width: 2px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-style: solid;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
}

/* ---------------------------------------------------------------------------------------- */
/* --------------------------------------- APP MENU --------------------------------------- */
/* ---------------------------------------------------------------------------------------- */

.width-menu {
  width: 100%;
}

@media (min-width: 1024px) {
  .width-menu {
    width: 260px;
  }
}

/* ---------------------------------------------------------------------------------------- */
/* ------------------------------------ APP CONTAINER ------------------------------------- */
/* ---------------------------------------------------------------------------------------- */

.margin-container {
  margin-left: 0px;
  margin-top: 80px;
}

@media (min-width: 1024px) {
  .margin-container {
    margin-left: 260px;
    margin-top: 0px;
  }
}

/* ---------------------------------------------------------------------------------------- */
/* -------------------------------------- APP HEADER -------------------------------------- */
/* ---------------------------------------------------------------------------------------- */

.height-header {
  height: 200px;
}

/* ---------------------------------------------------------------------------------------- */
/* ----------------------------------- PRODUCT CATEGORY ----------------------------------- */
/* ---------------------------------------------------------------------------------------- */

.product-category-container {
  height: 160px;
  border-radius: 6px;
  border-style: solid;
  border-width: 2px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

  .product-category-container:hover .product-category-title {
    background-color: rgba(99, 102, 241);
  }

.product-category-image {
  flex: 1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-category-title {
  padding: 6px;
  border-top-style: solid;
  border-top-width: 2px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  text-align: center;
}

.product-category-container-empty {
  height: 160px;
  border-radius: 6px;
  border-style: solid;
  border-width: 2px;
  display: flex;
  flex-direction: column;
}

/* ---------------------------------------------------------------------------------------- */
/* ------------------------------------ RESEARCH CHOICE ----------------------------------- */
/* ---------------------------------------------------------------------------------------- */

.research-choice {
  width: 160px;
  height: 160px;
}
